//
// Theme style
//
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Merriweather:wght@400;700&display=swap');


// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base";
@import "layout/layout";


table {
  td.last-td {
    position: relative;
    z-index: 3;

    &.shadow:before {
      content: '';
      width: 50px;
      height: 100%;
      position: absolute;
      right: 0px;
      background-color: transparent;
      box-shadow: 32px 32px 64px rgba(52, 64, 84, 0.08);
    }
  }

}


.text-right {
  text-align: right;
}

.aside-dark .menu .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here) .menu-title, .aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-title {
  //font-weight: 600;
  font-size: 14px;
}

.aside-dark .menu .menu-item .menu-link.active .menu-title {
  //font-weight: 600;
  font-size: 14px;
}


.btn-check:checked + .radio-button-custom {
  background-color: var(--kt-primary-light) !important;
}

.bg-init {
  background-color: #0D47A1;
}

.menu {
  .menu-item {
    .menu-link {
      .menu-title {
        font-size: 14px;
      }
    }
  }
}


.modal-revert-transactions {
  .ant-modal-close {
    display: none;
  }

  .ant-modal-content {
    border-radius: 12px;
    background-color: var(--kt-card-bg) !important;
  }

  .btn-revert {
    background-color: #D92D20;
    color: #fff;
  }

  .ant-modal-body {
    padding: 26px 32px;
  }
}

.checkout{
  transition: all .2s ease;
}

.bg-custom-dark{
  background: #16151A;
}

.ant-tooltip-inner {
  background-color: var(--kt-card-bg);
  color: var(--kt-text-gray-900);
  border-radius: 8px;

}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: var(--kt-card-bg);
}

.logo-form-upload {
  width: 126px;
  height: 126px;
  border-radius: 50%;
  border: 1px solid rgba(5, 5, 4, 0.05);
  overflow: hidden;
  margin-right: 20px;
}


.logo-table-admin {
  width: 32px;
  flex: 0 0 auto;
  height: 32px;
  border: 1px solid rgba(5, 5, 4, 0.05);
  border-radius: 4px;
  overflow: hidden;
  margin-right: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.ant-notification{
  z-index: 5000!important;
}
.logo-project {
  width: 160px;
  height: 160px;
  border-radius: 0.475rem;
}

.ant-picker-dropdown {
  [data-theme="light"] & {
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background-color: #009EF7;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border-color: #009EF7;
    }

    .ant-picker-cell-in-view.ant-picker-cell-in-range::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
      background-color: #E6F7FF;
    }
  }

  [data-theme="dark"] & {
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background-color: #E9532F;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border-color: #E9532F;
    }

    .ant-picker-cell-in-view.ant-picker-cell-in-range::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
      background-color: #FFEFEB;
    }
  }
}

.toolbar {
  [data-theme="light"] & {
    .ant-picker-range .ant-picker-active-bar {
      background-color: #009EF7;
    }

    .ant-picker:hover, .ant-picker-focused {
      border-color: #009EF7;
    }
  }

  [data-theme="dark"] & {
    .ant-picker-range .ant-picker-active-bar {
      background-color: #E9532F;
    }

    .ant-picker:hover, .ant-picker-focused {
      border-color: #E9532F;
    }
  }
}


.aside {
  display: flex;
  flex-direction: column;
  box-shadow: var(--kt-aside-box-shadow);
  padding: 0;

  .btn_custom_primary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;

    [data-theme="light"] & {
      @include button-custom-variant(
              $color: #009EF7,
              $icon-color: null,
              $border-color: null,
              $bg-color: rgba(0, 158, 247, 1),
              $color-active: #B5B5C3,
              $icon-color-active: null,
              $border-color-active: null,
              $bg-color-active: rgba(0, 158, 247, 1)
      );
    }

    .btn-label {
      transition: opacity get($aside-config, transition-speed) ease;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      opacity: 1;
    }

    .btn-icon {
      display: inline-block;
      transition: opacity get($aside-config, transition-speed) ease;
      overflow: hidden;
      opacity: 0;
      margin: 0;
      width: 0;
    }
  }

  // Dark theme
  &.aside-dark {
    background-color: var(--kt-aside-bg-color);

    .btn_custom_primary {
      [data-theme="dark"] & {
        @include button-custom-variant(
                $color: #E9532F,
                $icon-color: null,
                $border-color: null,
                $bg-color: #E9532F,
                $color-active: #CF4B2B,
                $icon-color-active: null,
                $border-color-active: null,
                $bg-color-active: #CF4B2B
        );
      }

      .btn-icon {
        &,
        i,
        .svg-icon {
          color: #B5B5C3;
        }
      }
    }
  }

  // Light theme
  &.aside-light {
    background-color: var(--kt-aside-bg-color);
  }
}

// Desktop mode
@include media-breakpoint-up(lg) {

  .aside {
    width: get($aside-config, width);
    transition: width get($aside-config, transition-speed) ease;

    .aside-footer-button-wrapper {
      [data-kt-aside-minimize="on"] & {
        width: 0;
        display: none;
      }
    }

    .aside-footer-button {
      width: 17px;
      height: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      .aside-footer-button-wrapper {
        width: auto;
        display: inline-block;
      }
    }

    // Minimize aside mode
    [data-kt-aside-minimize="on"] & {
      width: get($aside-config, minimized-width);
      transition: width get($aside-config, transition-speed) ease;

      // Not hovered mode
      &:not(.aside-hoverable),
      &:not(:hover) {

        .btn_custom_primary {
          .btn-label {
            width: 0;
            opacity: 0;
            transition: opacity get($aside-config, transition-speed) ease;
          }

          .btn-icon {
            width: auto;
            opacity: 1;
            transition: opacity get($aside-config, transition-speed) ease;
          }
        }
      }
    }
  }
}


.btn-dashed {
  border: 1px dashed #B5B5C3 !important;
  color: #B5B5C3;

  &:hover {
    border: 1px dashed var(--kt-primary) !important;
    color: #B5B5C3;
  }
}

.bg-brown {
  background: #B79384;
}

.bg-dark-blue {
  background: #3445E5;
}

.toolbar-filter {
  background: transparent !important;

  .filter-dropdown {
    position: absolute;
    top: 50px;
    right: -10px;

  }

  .filter_buttons_group {
    .btn {
      font-weight: 600;
      padding: calc(0.5rem + 0px) calc(1rem + 1px) !important;
      margin: 0 .5px;


      &.btn-active {
        color: var(--kt-primary) !important;
        border-color: var(--kt-primary-light) !important;
        background-color: var(--kt-primary-light) !important;
      }
    }


  }


  .form-control-lg {
    padding: 5px 10px !important;
    min-height: calc(1.2em + 1.35rem + 2px) !important;
    font-size: 1rem !important;
  }
}

.ant-drawer-body {
  background-color: var(--kt-body-bg);
}

.ant-dropdown {
  ul {
    background-color: var(--kt-input-solid-bg);

    li {
      color: var(--kt-input-solid-color);

      &:hover {
        background-color: #009ef7 !important;
        color: #fff !important;
      }
    }
  }

}

.custom-dropdown {

  ul {
    background-color: var(--kt-input-solid-bg);

    li {
      color: var(--kt-input-solid-color);

      &:hover {
        background-color: transparent!important;
        color: #fff !important;
      }
    }
  }

}

.page-title-header-main {
  position: fixed;
  z-index: 100;
  top: 0;
  padding: 10px 0;
}

.ant-picker-panel-container {
  .ant-picker-panel {
    background-color: var(--kt-input-solid-bg);

    .ant-picker-header {
      border-color: var(--kt-input-solid-color);
      color: var(--kt-input-solid-color);

      button {
        color: var(--kt-input-solid-color);
      }
    }

    th {
      color: var(--kt-input-solid-color);
    }

    td {
      color: var(--kt-input-solid-color);
      opacity: .25;

      &.ant-picker-cell-in-view {
        opacity: 1;
      }
    }
  }
}

.datepicker_form {
  background-color: var(--kt-input-solid-bg) !important;
  border-color: var(--kt-input-solid-bg) !important;
  color: var(--kt-input-solid-color) !important;
  transition: color 0.2s ease;
  border: 1px solid var(--kt-input-border-color) !important;
  box-shadow: none !important;

  &.bg-white-input {
    background-color: var(--kt-card-bg) !important;
  }

  .ant-picker-input > input {
    color: var(--kt-input-solid-color) !important;
  }

  .ant-picker-clear {
    & svg {
      fill: var(--kt-gray-600) !important;
    }
  }

  &:hover {
    .ant-picker-suffix {
      opacity: 0;
    }
  }

  .anticon-close-circle {
    background-color: var(--kt-input-solid-bg) !important;
  }

  .close-circle {
    background-color: var(--kt-input-solid-bg) !important;

  }

  .ant-picker-range-separator svg {
    fill: var(--kt-input-solid-color) !important;
  }

  .ant-picker-suffix svg {
    fill: var(--kt-input-solid-color);
  }
}

.user-list__block:hover {
  & > .menu-sub-dropdown {
    display: flex !important;
    z-index: 105;
    position: fixed;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate(-30px, 53px);
  }
}

.form-control-lg {
  padding: 4px 11px 4px !important;
}

.logo-form-preview {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin: 15px auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.logo-table {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.flex-40 {
  flex: 0 0 48%;
}

.flex-100 {
  flex: 0 0 100%;
}

.alert-success {
  left: 50%;
}

.error-icon {
  width: 80px;
  height: 80px;
  border: 4px solid var(--kt-danger);
  border-radius: 50%;
  margin: 0 auto;

  i {
    font-size: 30px;
    color: var(--kt-danger);
  }
}

.accordion-button {
  font-size: 20px !important;
}

.button_type {
  [data-theme="light"] & {
    color: #009EF7 !important;
    border: 1px solid #009EF7 !important;

    &:hover {
      color: #FFFFFF !important;
      background-color: #009EF7 !important;
    }

    &:active {
      color: #FFFFFF !important;
      background-color: #009EF7 !important;
    }
  }

  [data-theme="dark"] & {
    color: #E9532F !important;
    border: 1px solid #E9532F !important;

    &:hover {
      color: #FFFFFF !important;
      background-color: #E9532F !important;
    }

    &:active {
      color: #FFFFFF !important;
      background-color: #E9532F !important;
    }
  }

  background-color: transparent !important;
  transition: 0.5s;
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-picker-clear {
  background: none;
  color: #ffffffb5;
  right: 10px !important;

}

.ant-picker-clear:hover {
  color: #ffffffb5;
}

@media (max-width: 1024px) {
  .dash_sum {
    font-size: 16px !important;
  }
}

.bg-auth_top {
  position: absolute;
  top: 0;
  background: linear-gradient(132.57deg, rgba(246, 139, 46, 0.56) -1.39%, rgba(246, 187, 92, 0.143833) 71.51%);
  mix-blend-mode: normal;
  filter: blur(117px);
  width: 570px;
  height: 350px;
  left: -80px;
}

.bg-auth_bot {
  position: absolute;
  background: linear-gradient(169.06deg, #F6BB5C 8.01%, #F68B2E 90.79%);
  opacity: 0.6;
  filter: blur(125px);
  border-radius: 1000px;
  width: 460px;
  height: 420px;
  bottom: -100px;
  right: -120px;

}

.text-auth {
  position: absolute;
  font-size: 16px;
  color: #fff;
  margin: 0;
  bottom: 35px;
}

.color-orange {
  color: var(--kt-aside-button-icon-color) !important;
}

.auth_info {
  div {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  p {
    margin: 0;
    font-weight: 700;
    font-size: 26px;
    color: #181C32;
  }
}

.google_like_avatar_placeholder {
  font-family: 'Roboto', sans-serif;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}

.ant-picker-range-separator {
  padding: 0 8px 0 0;
}

.transaction-id-overflow {
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  width: 100px;
}


/*
OTP
*/

.input-otp {
  height: 80px !important;
  line-height: normal;

  margin-right: 8px;
  font-weight: 500;
  font-size: 40px;
}


.padding-left {
  padding-left: 45px !important;
}

.epos-remove {
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-end;
  margin-left: auto;
  text-align: right;
}

.card-type-icon {
  position: absolute;
  left: 14px;
  top: 67%;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
}

.input-otp input::placeholder {
  color: #D0D5DD;
}

.input-otp:nth-child(4) input {
  margin-left: 6px;
}

.text-left {
  text-align: left !important;
}

.input-otp span {
  font-weight: 500;
  font-size: 48px;
  padding-bottom: 13px;
  line-height: 26px;
  color: #D0D5DD;
}

.input-otp {
  width: 15% !important;
  margin-right: 8px;
}

.input-otp:last-child {
  margin-right: 0;
}

.input-otp:nth-child(3) {
  margin-right: 44px;
}

.input-otp input {
  margin-right: 0px !important;
  border-radius: 8px;
}

.input-otp:nth-child(4) input {
  margin-left: 0px !important;
}


.input-otp span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: 0 auto;
  left: 50%;
  font-size: 32px !important;
}


.valid-errors-otp input {
  border: 2px solid #f1416c !important;
  color: #f1416c !important;
}


.valid-errors-otp input:focus {
  background-clip: padding-box;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2 !important;
}

.input-otp input:focus {
  background-clip: padding-box;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(91, 154, 249, 0.1);
  border-radius: 8px;
  outline: none !important;
  border: 2px solid #5B9AF9;
}

.input-otp input:valid {
  border-width: 2px !important;
  opacity: 1;
}

.input-otp:first-child span, .input-otp:nth-child(2) span, .input-otp:nth-child(4) span, .input-otp:nth-child(5) span {
  display: none;
}

.input-otp input:last-child {
  margin-right: 0;
}


.input-otp input {
  display: block;
  width: 100% !important;
  height: 100% !important;
  font-weight: 500;
  font-size: 32px;
  background: none;
  opacity: 1;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  -webkit-box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.input-otp input:placeholder-shown {
  display: block;
  width: 100% !important;
  height: 100% !important;
  font-weight: 500;
  font-size: 32px;
  background: none;
  opacity: .5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}


.disabled {
  opacity: .7;
  pointer-events: none;
}


@media (max-width: 992px) {
  .auth_cabinet {
    flex-grow: 1;
  }
  .text-auth {
    position: static;
  }
  .bg-auth_top, .bg-auth_bot {
    display: none;
  }
}

@media (max-width: 768px) {
  .bg_auth {
    .link_logo {
      width: 160px !important;
    }
  }

  .text-auth {
    font-size: 14px;
  }
}

@media (max-width: 576px) {

}




