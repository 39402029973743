$color-bg-light: #fff;
$color-bg-dark: #16151A;
$color-bg-dark2: #201F25;
$color-text-light: #646774;
$color-text-dark:  #8888A2;

.checkout-wrap{
  font-family: 'Inter', sans-serif!important;

  &.theme-dark{
    .checkout-edit-view{
      background: $color-bg-dark;
    }

    .invoice-block{
      background: $color-bg-dark2;
    }
    .color-text-inverse{
      color: #fff;
    }
    label{
      color: $color-text-dark;
    }
    input{
      border: 1px solid transparent;
      background: #201F25;
    }

    .color-text{
      color: $color-text-dark;
    }
  }

  &.theme-light{
    .checkout-edit-view{
      background: $color-bg-light;
    }
    .invoice-block{
      background: var(--Gradient-grey, linear-gradient(238deg, rgba(236, 231, 244, 0.50) -7.47%, #F2F4F5 69.88%, #F2F4F5 100%));

    }
    label{
      color: $color-text-light;
    }
    .color-text-inverse{
      color: #111;
    }
    input{
      border: 1px solid #CFD0D4;
      background: #fff;
    }

    .color-text{
      color: $color-text-light;
    }
  }

  .checkout-edit-view-wrap{
    padding-right: 64px;

    .checkout-edit-view{
      border: 1px solid #CFD0D4;
      border-radius: 8px;
      padding: 14px;

      & > div:first-child{
        padding-right: 30px;
      }


      .input-group-checkout{
        margin-bottom: 15px;
        label{
          font-size: 8px;
        }
        input{
          padding: 5px;
          border-radius: 4px;
          width: 100%;
        }
      }

      .card-group-input{
        right: 10px;
        top: 20%;
        span{
          width: 9px;
          height: 9px;
          margin-right: 4px;
          opacity: .5;

          img{
            width: 100%;
            height: 100%;
          }
        }
      }


      button{
        outline: none;
        border-radius: 4px;
        padding: 8px;
        margin-bottom: 8px;
      }


      .invoice-block{
        padding: 7px 15px;

        .header-block{
          margin-top: 13px;
          margin-bottom: 35px;
          .company-logo{
            width: 20px;
            height: 20px;
            margin-bottom: 15px;
          }
        }

        .invoice-price-info, .invoice-price-result{
          margin-top: 15px;

          p{
            margin-bottom: 15px!important;
          }
        }

        .checkout-icons{
          margin-top: 8px;
          div{
            margin-right: 4px;
          }
        }

        .line{
          opacity: .2;
          border-bottom: 1px solid #646774;
        }

      }
    }
  }



  .settings-toolbar{
    padding: 32px 0px 32px 64px;
    border-left: 1px solid #EEEFF3;

    .block-title{
      color: #111;
    }

    .toolbar-panel{
      margin-top: 24px;

      .sub-title{
        color: #344054;
        margin-bottom: 6px;
      }

      .switcher-theme{
        border-radius: 8px;
        padding:10px 14px;
        border: 1px solid #D0D5DD;
        color: #667085;
        margin-right: 6px;
        background: #fff;

        span{
          margin-right: 4px;
        }

        &.switch-active{
          border-color: #000;
          color: #000;
        }
      }
    }
  }
}